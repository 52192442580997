import Vue from 'vue'
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            alias: "/home",
            name: "home",
            component: () => import("./components/Login.vue")
        },
        {
            path: "/product",
            alias: "/product",
            name: "product",
            component: () => import("./components/Product.vue")
        },
        {
            path: "/howtoorder",
            alias: "/howtoorder",
            name: "howtoorder",
            component: () => import("./components/Howtoorder.vue")
        },
        {
            path: "/payment",
            alias: "/payment",
            name: "payment",
            component: () => import("./components/Payment.vue")
        },
        {
            path: "/blog",
            alias: "/blog",
            name: "blog",
            component: () => import("./components/Blog.vue")
        },
        {
            path: "/aboutus",
            alias: "/aboutus",
            name: "aboutus",
            component: () => import("./components/Aboutus.vue")
        },
        {
            path: "/contactus",
            alias: "/contactus",
            name: "contactus",
            component: () => import("./components/Contactus.vue")
        },
        {
            path: "/login",
            alias: "/login",
            name: "login",
            component: () => import("./components/Login.vue")
        }
    ]
});