<template>
  <div id="app">
    <nav v-if="isMenu"
      class="navbar navbar-expand-lg navbar-light"
      style="background-color: #e3f2fd"
    >
      <a class="navbar-brand" href="#">
        <img
          src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg"
          width="30"
          height="30"
          class="d-inline-block align-top"
          alt=""
        />
        สวนลุงนาจ
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <router-link to="/home" class="nav-item nav-link" href="#"
            >หน้าแรก <span class="sr-only">(current)</span></router-link
          >
          <router-link to="/product" class="nav-item nav-link" href="#"
            >สินค้าทั้งหมด</router-link
          >
          <router-link to="/howtoorder" class="nav-item nav-link" href="#"
            >วิธีการสั่งซื้อสินค้า</router-link
          >
          <router-link to="/payment" class="nav-item nav-link" href="#"
            >แจ้งชำระเงิน</router-link
          >
          <router-link to="/blog" class="nav-item nav-link" href="#"
            >บทความ</router-link
          >
          <router-link to="/aboutus" class="nav-item nav-link" href="#"
            >เกี่ยวกับเรา</router-link
          >
          <router-link to="/contactus" class="nav-item nav-link" href="#"
            >ติดต่อเรา</router-link
          >
           <router-link to="/login" class="nav-item nav-link" href="#"
            >Login</router-link
          >
        </div>
      </div>
    </nav>

    <div class="container mt-3">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      isMenu:false
    }
  }
};
</script>

<style>
</style>
