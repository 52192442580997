import Vue from 'vue'
import App from './App.vue'
import router from './router'
import facebookLogin from 'facebook-login-vuejs';

import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId: '980391602017-jimh5r8mmqbmiaoffs8o7rsbsojie95f.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: true
}
Vue.use(GAuth, gauthOption)
Vue.config.productionTip = false
Vue.component('login', {
  components: {
      facebookLogin
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
